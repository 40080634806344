<template>
<header>
  <div class="logo"><img src="@/assets/logo.svg" /></div>
  <div class="social">
  <a href="https://www.linkedin.com/company/mtibrands/" target="_blank"><i data-feather="linkedin"></i></a>
  </div>
</header>

<section class="hero black">
<swiper
    :modules="modules"
    :slides-per-view="1"
    :space-between="30"
    :effect="'fade'"
    :autoplay="{
      delay: 5000,
      disableOnInteraction: false,
    }"
    :pagination="{ clickable: true }"
    @swiper="onSwiper"
    id="mySlider"
  >
    <swiper-slide>
      <div class="item s01">
      <div class="text">
        <h1>Creativity is intelligence having fun</h1>
        <h2>– Albert Einstein</h2>
      </div>

      </div>

    </swiper-slide>
    <swiper-slide>
      <div class="item s02">
      <div class="text">
        <h1>Think big; think disruptive. Execute with full passion.</h1>
        <h2>– Masayoshi Son</h2>
      </div>
      </div>

    </swiper-slide>


    <swiper-slide>
      <div class="item s03">
      <div class="text">
        <h1>Innovation distinguishes between a leader and a follower</h1>
        <h2>– Steve Jobs</h2>
      </div>
      </div>

    </swiper-slide>


    <swiper-slide>
      <div class="item s04">
      <div class="text">
        <h1>The value of a company is the sum of the problems you solve</h1>
        <h2>– Daniel Ek</h2>
      </div>
      </div>

    </swiper-slide>



  </swiper>
  <div class="wrapper">


  </div>
</section>

<section class="marketing">
  <div class="wrapper">
    <div class="row">
    <div class="col">
        <h2>Marketing Strategic Alliances</h2>
        <p>MTI Brands develops strategic alliances with technological platforms to create value between all participating brands.</p>
        <div class="brands">

        <h3>Business, Finance & Self-Fullfilment</h3>
        <img src="@/assets/invermondo.png">

      <p>Invermondo is an innovative community and platform that promotes financial education, entrepreneurship and personal self-realization through unique experiences and exclusive rewards.</p>
        <div class="vspacer"></div>

        <h3>Fashion & Lifestyle Marketing</h3>
      <img src="@/assets/modelomania.png">

      <p>Modelomania is an innovative platform that connects premium brands with models, brand ambassadors and content creators, facilitating collaborations to promote products and services among its registered users.</p>
      <div class="vspacer"></div>
        <h3>Sports & Wellness Marketing</h3>
          <img src="@/assets/promorunner.png" style="max-width:250px">

          <p>Promorunner is a platform designed to encourage and promote sport, offering users the opportunity to participate in various running and fitness activities.</p>
          <div class="vspacer"></div>

        </div>
    </div>
        <div class="col">
        <div class="player">
        <div class='embed-container'><iframe src='https://www.youtube.com/embed/Nf9F5y7kbuk' frameborder='0' allowfullscreen></iframe></div>
        </div>

        </div>

    </div>
  </div>
</section>

<section class="technology black">
  <div class="wrapper">
    <h2>Technology</h2>
    <p>We deliver reliability, innovation and digital knowledge across a variety of digital platforms.</p>

    <div class="row c3">
      <div class="col">
      <div class="item">
        <img src="@/assets/user-experience.png">
        <h3>User Experiences</h3>
        <p>Incredible user experiences feel intuitive. We get close to your customers to find out what they need. Then design for maximum adoption, engagement and enjoyment.</p>
      </div>

      </div>
      <div class="col">
        <div class="item">
        <img src="@/assets/data.png">
          <h3>Engineering, Programming and Design</h3>
          <p>World-class products hit key metrics and win markets. We design, architect, concept, prototype and test to discover the right product to build and meet those goals.</p>
        </div>
      </div>
      <div class="col">
      <div class="item">
      <img src="@/assets/cloud.png">
        <h3>Product</h3>
        <p>Great products satisfy user needs, rely on intelligent technology choices and focus on business outcomes. We help you achieve product driven growth.</p>

      </div>
      </div>
    </div>
  </div>
</section>

<section class="neocivility">
    <div class="wrapper">
        <div class="row">
            <div class="col">
              <h2>Content Production</h2>
              <p>MTI Brands delivers premium, high-impact content tailored for top-tier brands. We blend education and entertainment seamlessly, crafting compelling narratives that inspire, engage, and create meaningful connections. Our commitment to excellence ensures that every production reflects the highest standards in concept, execution, and quality, fostering a positive influence on society.</p>
            </div>
            <div class="col empty"></div>
        </div>

        <div class="row">
            <div class="col"><div class='embed-container'><iframe src='https://www.youtube.com/embed/htC_W_UnHio' frameborder='0' allowfullscreen></iframe></div></div>
            <div class="col"><div class='embed-container'><iframe src='https://www.youtube.com/embed/rHOwlU64tbc' frameborder='0' allowfullscreen></iframe></div></div>
        </div>
        <br>
        <div class="social">
        <p align="center">Follow us</p>
        <a href="https://www.linkedin.com/company/neocivility/" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin-in" /></a>

</div>
    </div>
  </section>


  <section class="neocivility black">
      <div class="wrapper">
          <div class="row">
              <div class="col">
                <h2>Personal Branding</h2>
                <p>At MTI Brands, we transform your identity into an authentic and powerful brand. By combining strategy, creativity, and technology, we help you stand out in the digital world and connect with your audience effectively.</p>
                <br>
                <p>Whether you’re a content creator, business leader, or brand ambassador, we design personalized strategies that reflect the best of you and your vision. With MTI Brands, you’re not just building a brand—you’re creating a legacy that inspires and leaves a lasting impact.</p>
              </div>
              <div class="col"><img src="@/assets/portada-pb.png" style="max-width:100%;"/></div>

          </div>



      </div>
    </section>

  <section class="legados black">
    <div class="wrapper">
      <div class="row">
        <div class="col"> </div>
          <div class="col">
            <h2>Legados de México</h2>
            <p>MTI Brands participates in the initiative of renowned Mexican photographer Héctor Rosas, to portray, promote and keep alive the legacy of our proud Mexican culture.</p>
            <br/>
            <p class="gray">Mid 2024</p>
          </div>

      </div>
    </div>
  </section>

  <footer>
    <div class="wrapper">
      <div class="row">
        <div class="col"><div class="logo"><img src="@/assets/logo.svg" /></div></div>
        <div class="col"><p>Copyright © MTIBrands.com All rights reserved. </p></div>
      </div>
    </div>
  </footer>
</template>

<script>
import feather from 'feather-icons';
import { Navigation, Pagination, Scrollbar,Autoplay,EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/effect-fade"
import "@/assets/swiper.css"


export default {
  components: { Swiper, SwiperSlide, },
  data: () => ({
    selectedDate: new Date(),
    calls: [],
    assets: [],
    blockCalendar: false,
    elPlayer: null,

  }),
  name: 'HomeView',
  props: {
    msg: String
  },
  mounted(){
  feather.replace();
  },
  setup() {
      const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
        modules: [Navigation, Pagination, Scrollbar,Autoplay,EffectFade],
      };
    },
  created() {


  },
  updated(){
    this.$nextTick(function () {

    });
  },
   methods: { }
}

</script>

<style scoped>

header { position:absolute; left:0; right:0; top:0; z-index: 10; width:95%; text-align:left; padding:30px;  margin:auto;}
header img { max-width:120px; }
header .social { position:absolute; right:20px; top:70px;}
header .social a  { color:#fff; margin-left:10px; font-size:12px;}
header .social a:hover { color:#ffbb1f;  }
header .social a i{ color:#fff; width:20px; height:20px; }

footer { background:#000; color:#fff; min-height:60px; display: flex;  flex-direction: column;}
footer .wrapper { height:100%; width:95%; margin:auto;   }
footer p { font-size:14px; text-align:right; color:#777;}
footer .logo { max-width:60px;}

section { min-height:100vh; display: flex;  flex-direction: column;}
section .wrapper { height:100%; width:95%; margin:auto;   }
section.black { background:#000;}
section.black  { color:#fff;}

p { line-height: 24px; }

section.hero { justify-content: center;  position:relative; }
section.hero::after { position:absolute; top:0; left:0; right:0; bottom:0; width:100%; height:100%;  content:"";}
section.hero .wrapper { position:relative; z-index:10; padding:30px;}


.item.s01 { background:url('~@/assets/s01.jpg') center center; background-size:cover; height:100vh; }
.item.s02 { background:url('~@/assets/s02.jpg') center center; background-size:cover; height:100vh;}
.item.s03 { background:url('~@/assets/s03.jpg') center center; background-size:cover; height:100vh;}
.item.s04 { background:url('~@/assets/s04.jpg') center center; background-size:cover; height:100vh;}

#mySlider { height:100vh;}
#mySlider .swiper-slide .item   { display: flex; position:relative; }
#mySlider .swiper-slide .item::after { content:""; position:absolute; background:rgba(0,0,0,.7); width:100%; height:100%; }
#mySlider .swiper-slide .item .text { flex: 1;  align-self: center; justify-content: center; z-index:10; }



#mySlider .swiper-slide .item .text h1 {  text-align: left; font-size:80px; margin-bottom:0px; max-width:60%; padding-left:30px;}
#mySlider .swiper-slide .item .text h2 { text-align: left; font-size:30px; font-weight: 400; color:#ffbb1f; padding-left:30px;}

section.marketing { justify-content: center;}
section.marketing .wrapper { padding:30px;}
section.marketing .wrapper h2 { text-align: left; font-size:70px;color:#000; margin:0; line-height: 80px;  margin-bottom:20px; }
section.marketing .wrapper p { text-align:left; margin:0; color:#777; max-width:80%;}
section.marketing .brands { margin-top:30px; text-align:left;}
section.marketing .brands img { max-width:200px; display:inline-block; margin-bottom:5px;}
section.marketing .brands img:first-child { margin-right:30px;}
section.marketing .brands .vspacer { min-height:30px;}
section.marketing .brands h3 { font-weight:500; margin-top:10px; margin-bottom:10px; font-size:14px; }
section.marketing .row .col .player { padding:25px;}

section.technology { overflow:hidden; position:relative; background:url('~@/assets/bck_tech.jpg') center center; background-size:cover; }
section.technology::after { position:absolute; top:0; left:0; right:0; bottom:0; width:100%; height:100%; background:rgba(0,0,0,.60);  content:"";}

section.technology .wrapper { position:relative; z-index:10;}
section.technology .wrapper h2 { text-align: center; font-size:80px;color:#fff; margin:0; line-height: 80px;  margin-bottom:20px; }
section.technology .wrapper p { text-align:center; margin:0; color:#ffbb1f; margin-top:20px; margin-bottom:20px;}
section.technology .wrapper p.gray { color:#fff;}

section.technology .row.c3 { max-width:1080px; margin:auto; margin-bottom:30px;}
section.technology .row.c3 .col {width:33.33%; display:inline-block; vertical-align:top;}
section.technology .row.c3 .col .item { padding:20px; border:#fff solid 1px; border-radius:12px; margin:15px; position:relative; z-index:1; min-height:330px;}
section.technology .row.c3 .col .item::before { background-color:rgba(0,0,0,.60); filter:blur(2px); content:""; position:absolute; top:0; left:0; bottom:0; width:100%; z-index:-1; }
section.technology .row.c3 .col .item h3 { font-size:16px; min-height:40px;}
section.technology .row.c3 .col .item img { max-width:50px;}
section.technology .row.c3 .col .item  p { color:#fff; z-index:100;}

section.neocivility { justify-content: center; }
section.neocivility .wrapper h2 { text-align: left; font-size:80px; color:#000; margin:0;  margin-bottom:20px;}
section.neocivility .wrapper h2 img { max-width:400px; width:100%;}
section.neocivility .wrapper p { text-align:left; margin:0; color:#777;}
section.neocivility .wrapper .row .col { padding:20px;}
section.neocivility .wrapper .social { margin-bottom:30px;}
section.neocivility .wrapper .social p { text-align:center; margin-bottom:15px;}
section.neocivility .wrapper .social a { border:#333 solid 1px; width:40px; height:40px; border-radius:100px; display:inline-block; margin-left:5px; margin-right:5px; text-align:center; line-height:38px; color:#333;}
section.neocivility .wrapper .social a:hover { background-color:#333; color:#fff;}

section.neocivility.black { justify-content: center; }
section.neocivility.black .wrapper h2 { text-align: left; font-size:80px; color:#fff; margin:0;  margin-bottom:20px;}
section.neocivility.black .wrapper p { text-align:left; margin:0; color:#fff;}


section.legados { justify-content: center; background:url('~@/assets/legados/bck.jpeg') center center; background-size:cover; position:relative; display:none; }
section.legados::after { position:absolute; top:0; left:0; right:0; bottom:0; width:100%; height:100%; background:rgba(0,0,0,.60);  content:"";}
section.legados .wrapper { position:relative; z-index:10;}
section.legados .wrapper h2 { text-align: left; font-size:80px; color:#fff; margin:0; margin-bottom:20px; line-height: 80px;  }
section.legados .wrapper p { text-align:left; margin:0; color:#ffbb1f;}
section.legados .wrapper p.gray { color:#999;}

section.legados .wrapper .row .col { padding:20px;}

.swiper { width:100%;  }
.swiper .swiper-slide {}
.swiper .swiper-slide .item  { width:100%;  border-radius:12px; overflow:hidden;}
.swiper .swiper-slide .item img { width:100%;}

.row {}
.row .col { width:50%; display:inline-block;  vertical-align: middle;}


.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; border-radius:6px; }
.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

@media only screen and (max-width: 600px) {
  section .wrapper { width:90%;  }
  section.technology .wrapper { padding:20px;}
  section.technology .wrapper h2 { font-size:40px;}
  section.marketing .wrapper h2 { font-size:40px; line-height:50px;}
  section.neocivility .wrapper h2 { font-size:40px !important; line-height:50px;}

  section.legados .wrapper h2 { font-size:40px; line-height:60px;}
  section.hero .wrapper { padding:20px;}
  section.hero .wrapper h1  { font-size:40px; max-width:100%;}

  #mySlider .swiper-slide .item .text h1  { font-size:36px; max-width:90%; text-align:center; margin:auto; padding-left:0px;}
  #mySlider .swiper-slide .item .text h2  {text-align:center; font-size:22px; padding-left:0px;}
  section.legados { background-position:left center;}
  .row .col { width:100%; }
  .row .col.empty { display:none;}

  section.marketing .row .col .player { padding:0px;}
  section.marketing .wrapper p { max-width:100%;}

  section.technology .row.c3 .col { width:100%; max-width:330px; margin:auto;}

  section.technology .wrapper h2 { text-align:left;}
  section.technology .wrapper p { text-align:left;}

    section.technology .row.c3 .col p { text-align:center;}

  section.marketing .wrapper { padding:20px; }
  section.marketing .brands { margin-top:15px; margin-bottom:15px;}
  section.marketing .brands img { margin-top:15px; margin-bottom:15px;}

  header img { max-width:100px;}
  header .social { top:50px;}

  footer .wrapper { width:90%; padding:20px;}
  footer .wrapper .row .col { width:100%; }
  footer .wrapper .row .col p { text-align:center;  }
  footer .logo { margin:auto;}
}

</style>
